
import { Component, Vue } from "vue-property-decorator";
import AlertBox from "@/components/AlertBox.vue";
import BackButton from "@/components/BackButton.vue";
import { AxiosError, AxiosResponse } from "axios";
import sentry from "@/plugins/sentry";
import SocialButtons from "@/components/SocialButtons.vue";
import LoadingButton from "@/components/LoadingButton.vue";
import ReferralApi from "@/services/api/referral";
import { ensureAuthenticated } from "@/plugins/firebase";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import TextLoadingSpinner from "@/components/TextLoadingSpinner.vue";
import { mdiHeartMultiple, mdiStar } from "@mdi/js";
import TwitterCard from "@/components/TwitterCard.vue";
import { isIos } from "@/plugins/utils";
import { ApiResponse } from "@/services/api/axios";

@Component({
    components: {
        TwitterCard,
        TextLoadingSpinner,
        LoadingSpinner,
        LoadingButton,
        SocialButtons,
        BackButton,
        AlertBox,
    },
})
export default class AffiliatesIndex extends Vue {
    isLoading: boolean = false;
    referralPayoutAmount: number | null = null;
    referralDiscountAmount: number | null = null;
    starIcon: string = mdiStar;
    heartIcon: string = mdiHeartMultiple;

    get isIos() {
        return isIos();
    }

    handleError(error: Error) {
        sentry.captureException(error);
        this.$root.$emit(
            this.$constants.NOTIFICATION_EVENTS.ERROR,
            error.message
        );
    }

    handleAxiosError(error: AxiosError<ApiResponse>) {
        this.handleError(
            new Error(error.response?.data?.message ?? error.message)
        );
    }

    loadReferral() {
        this.isLoading = true;
        ReferralApi.getSettings()
            .then((response: AxiosResponse) => {
                this.referralDiscountAmount =
                    response.data.data.discount_amount;
                this.referralPayoutAmount = response.data.data.payout_amount;
            })
            .catch(this.handleError)
            .finally(() => {
                this.isLoading = false;
            });
    }

    mounted() {
        ensureAuthenticated().finally(() => {
            this.loadReferral();
        });
    }
}
